var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"87c867531579686485e6c0ceb40609ae0998cb24"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
const ALLOW_SENTRY = process.env.NODE_ENV === 'production';

Sentry.init({
  environment: process.env.NODE_ENV,
  dsn: SENTRY_DSN,
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 0,
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
  enabled: ALLOW_SENTRY,

  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 1,

  integrations: [new Sentry.Replay()],

  beforeSend(event, hint) {
    const error = hint?.originalException;
    if (
      error &&
      error.message &&
      (error.message.match(/Failed to load Stripe.js/) ||
        error.message.match(
          /Cannot resolve a Slate node from DOM node: \[object HTMLParagraphElement\]/,
        ))
    ) {
      return null;
    }
    return event;
  },
});
